<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.加班明细')"
                 fixed
                 right-:text="$t('tbm.确认')"
                 @click-left="back"
                 @click-right="submitForm">
    </van-nav-bar>
    <div style="padding-top:46px;">
      <van-form ref="forms">
        <van-field readonly
                   clickable
                   required
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.dateDetail ? form.dateDetail.split(' ')[0] : form.dateDetail"
                   :label="$t('tbm.加班日期')"
                   :placeholder="$t('tbm.请选择加班日期')"
                   @click="showdateDetail = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择加班日期'),trigger: 'change'}]" />

        <van-calendar v-model="showdateDetail"
                      :min-date="minDate"
                      :default-date="defaultDate"
                      @confirm="dateDetailChange" />

        <van-field readonly
                   name="picker"
                   :value="typeconversion(form.dateType)"
                   :label="$t('tbm.加班类型')"
                   input-align="right"
                   placeholder=""
                   style="margin-bottom: 10px;" />

        <van-field readonly
                   clickable
                   required
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.beginTime"
                   :label="$t('tbm.开始时间')"
                   :placeholder="$t('tbm.请选择开始时间')"
                   @click="showBeginTime = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择开始时间'),trigger: 'change'}]" />

        <van-popup v-model="showBeginTime"
                   v-if="showBeginTime"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="form.beginTime"
                               type="time"
                               :max-hour="form.endTime != '00:00' ? form.endTime.split(':')[0] : '23'"
                               :max-minute="form.endTime != '00:00' ? form.endTime.split(':')[1] : '59'"
                               @confirm="beginTimeChange"
                               @cancel="showBeginTime=false"
                               :title="$t('tbm.选择开始时间')" />
        </van-popup>

        <van-field readonly
                   clickable
                   required
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.endTime"
                   :label="$t('tbm.结束时间')"
                   :placeholder="$t('tbm.请选择结束时间')"
                   @click="showEndTime = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择结束时间'),trigger: 'change'}]" />
        <van-popup v-model="showEndTime"
                   v-if="showEndTime"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="form.endTime"
                               type="time"
                               :min-hour="form.beginTime != '00:00' ? form.beginTime.split(':')[0] : '0' "
                               :min-minute="form.beginTime != '00:00' ? form.beginTime.split(':')[1] : '0'"
                               @confirm="endTimeChange"
                               @cancel="showEndTime=false"
                               :title="$t('tbm.选择结束时间')" />
        </van-popup>
        <van-field readonly
                   clickable
                   :value="form.totalHours"
                   :label="$t('tbm.加班时长')"
                   required
                   input-align="right"
                   placeholder=""
                   :rules="[{ required: true, message: this.$t('tbm.加班时长不能为空'),trigger: 'change'}]" />
        <van-field readonly
                   clickable
                   :value="form.totalDays"
                   :label="$t('tbm.折合天数')"
                   required
                   input-align="right"
                   placeholder=""
                   :rules="[{ required: true, message: this.$t('tbm.折合天数不能为空'),trigger: 'change'}]" />
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showdateDetail: false,
      minDate:new Date(2020, 0, 1),
      defaultDate: new Date(),
      showBeginTime: false,
      showEndTime: false,
      form: {
        userName: '',
        deptName: '',
        dateType: '',
        beginTime: '00:00',
        endTime: '00:00',
        totalHours: '',
        totalDays: '',
        dateDetail: '',
        id: ''
      },
      overTimeList: '',
      pkOrg: '',
      pkUser: '',
      workHoursBeginTime: '00:00',
      workHoursEndTime: '00:00',
      dateTypeName: '',


    }
  },
  methods: {
    back () {
      this.$router.replace({
        name: 'tbm/overtimeApplication/overtimeApplicationEdit',
        query: {
          ...this.$route.query
        },
        params: {
          ...this.$route.params,
        }
      })
    },
    typeconversion: (value) => {
      return value === '0' ? '法定节假日' : value === '1' ? '工作日' : value === '2' ? '休息日' : '';
    },
    //  根据日期获取 假日类型
    setDayType (dateDetail) {
      this.form.totalDays = ''
      this.form.totalHours = ''
      this.form.beginTime = '00:00'
      this.form.endTime = '00:00'
      var companyId = JSON.parse(localStorage.getItem('userInfo')).companyId;
      if (dateDetail !== '') {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/festivalSetting/getDayType',
          params: {
            dateDetail: this.form.dateDetail,
            pkOrg: companyId
          }
        }).then(res => {
          if (res.data.ext.length > 0) {
            var resultData = res.data.ext[0];
            var dateType = resultData.dateType;
            this.form.dateType = dateType;
            if (resultData.startTime !== '') {
              this.workHoursBeginTime = resultData.startTime;
              this.workHoursEndTime = resultData.endTime;
            } else {
              this.workHoursBeginTime = '00:00'
              this.workHoursEndTime = '00:00'
            }
          }
        });
      }
    },
    setHourAndDay () {
      var startTime = this.form.beginTime;
      var endTime = this.form.endTime;
      var workHoursBeginTime = this.workHoursBeginTime;
      var workHoursEndTime = this.workHoursEndTime

      //判断结束时间必须大于开始时间
      if (endTime !== "00:00") {
        if (this.getHour(startTime, endTime) === 0) {
          this.$notify({ type: 'danger', message: this.$t('tbm.开始时间不能大于结束时间') });
          return false
        }
      }
      // 计算加班时间之前 先判断排班时间 和加班时间是否重叠
      let result = this.checkTime(startTime, endTime, workHoursBeginTime, workHoursEndTime);
      if (result) {
        var totalHours = this.getHour(startTime + ':' + '00', endTime + ':' + '00');
        var totalDays = (parseFloat(totalHours) / 8).toFixed(1);
        //法定假日和休息日 加班超过8小时 按8小时计算
        if (this.form.dateType === this.TbmCons.OvertimeType.legalholidays || this.form.dateType === this.TbmCons.OvertimeType.restDay) {
          if (totalHours > 8) {
            totalHours = 8
            totalDays = 1
          }
        }
        this.form.totalHours = totalHours;
        this.form.totalDays = totalDays;
      }
    },
    getHour (s1, s2) {
      var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
      s1 = new Date((reDate.test(s1) ? s1 : '2020-1-1 ' + s1).replace(/-/g, '/'));
      s2 = new Date((reDate.test(s2) ? s2 : '2020-1-1 ' + s2).replace(/-/g, '/'));
      var ms = s2.getTime() - s1.getTime();
      if (ms < 0) return 0;
      return (ms / 1000 / 60 / 60).toFixed(1);
    },
    checkTime (a, b, x, y) {
      var times1 = [];
      var times2 = [];
      if (a < b) {
        // 未跨天
        times1.push([a, b]);
      } else {
        // 跨天
        times1.push([a, '24:00'], ['00:00', b]);
      }
      if (x < y) {
        times2.push([x, y]);
      } else {
        // times2.push([x, '24:00'], ['00:00', y]);
        times2.push([x, '00:00'], ['00:00', y]);
      }
      var flag = false;
      // 循环比较时间段是否冲突
      for (var i = 0; i < times1.length; i++) {
        if (flag) break;
        for (var j = 0; j < times2.length; j++) {
          if (this.check(times1[i][0], times1[i][1], times2[j][0], times2[j][1])) {
            flag = true;
            break;
          }
        }
      }
      if (flag) {
        this.$notify({ type: 'danger', message: this.$t('tbm.加班时间和排班时间重叠') });
        this.form.beginTime = '00:00'
        this.form.endTime = '00:00'
        this.form.totalDays = ''
        this.form.totalHours = ''
        return false;
      } else {
        return true;
      }
    },
    check (a, b, x, y) {
      if (y < a || b < x) {
        return false;
      } else {
        return true;
      }
    },
    beginTimeChange (value) {
      this.showBeginTime = false;
      if (this.form.endTime != '00:00') {
        this.setHourAndDay();
      }
    },
    endTimeChange (value) {
      this.showEndTime = false;
      if (this.form.endTime != '00:00') {
        this.setHourAndDay();
      }
    },
    dateDetailChange (date) {
      let flag=false
      let year=date.getFullYear()
      let month=(date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
      let day = date.getDate() >= 10 ? date.getDate() : ('0' + date.getDate())
      this.form.dateDetail = year+'-'+month+'-'+day;
      if(this.overTimeList.length>0){
        for(let i=0;i<this.overTimeList.length; i++ ){
          let currentDate=this.overTimeList[i].dateDetail.substr(0,10)
          if(currentDate===this.form.dateDetail){
            this.$notify({ type: 'danger', message: this.$t('tbm.当前日期存在加班') });
            this.form.dateDetail =''
            flag=true
            break;
          }
         }
      }
      if(flag){
        this.showdateDetail = false;
        return false
      }
      this.showdateDetail = false;
      this.setDayType();
    },
    submitForm () {
      let this_ =this;
      this.$refs.forms.validate().then(() => {
        let flag = true;
        if (this.getHour(this.form.beginTime, this.form.endTime) === 0) {
          this.$notify({ type: 'danger', message: this.$t('tbm.开始时间不能大于结束时间') });
          this.form.beginTime = '00:00'
          this.form.endTime = '00:00'
          this.form.totalDays = ''
          this.form.totalHours = ''
          flag = false
        }
         if(this_.overTimeList.length >0){
           for(let i=0;i<this_.overTimeList.length; i++ ){
             let currentData=this_.overTimeList[i]
             if(currentData.dateDetail.substring(0,10) ===this_.form.dateDetail ){
             let result = this.checkRepeatTime(this.form.beginTime,this.form.endTime,currentData.beginTime,currentData.endTime)
               if(result){
                 this.$notify({ type: 'danger', message: this.$t('tbm.加班时间时间重复') });
                 flag = false;
                 break;
               }
             }
           }

         }

        this.checkSealupAndRepeat().then((result) => {
          // true 这说明有重复日期申请
          if (result.data.hasRepeats) {
            this.$notify({ type: 'danger', message: this.$t('tbm.不能重复申请加班') });
            this.form.beginTime = '00:00'
            this.form.endTime = '00:00'
            this.form.totalDays = ''
            this.form.totalHours = ''
            flag = false;
          }
          //加班日期对应的周期已经封存 不能请假
          if (result.data.isSealUp) {
            this.$notify({ type: 'danger', message: '加班日期 对应的周期已封存' });
            this.form.beginTime = '00:00'
            this.form.endTime = '00:00'
            this.form.totalDays = ''
            this.form.totalHours = ''
            flag = false;
          }
        })
        setTimeout(() => {
          if (flag) {
            this.sendData();
          }
        }, 1000)
      })
    },


    checkRepeatTime (a, b, x, y) {
      var times1 = [];
      var times2 = [];
      if (a < b) {
        // 未跨天
        times1.push([a, b]);
      } else {
        // 跨天
        times1.push([a, '24:00'], ['00:00', b]);
      }
      if (x < y) {
        times2.push([x, y]);
      } else {
        // times2.push([x, '24:00'], ['00:00', y]);
        times2.push([x, '00:00'], ['00:00', y]);
      }
      var flag = false;
      // 循环比较时间段是否冲突
      for (var i = 0; i < times1.length; i++) {
        if (flag) break;
        for (var j = 0; j < times2.length; j++) {
          if (this.check(times1[i][0], times1[i][1], times2[j][0], times2[j][1])) {
            flag = true;
            break;
          }
        }
      }
      if (flag) {
        this.$notify({ type: 'danger', message: this.$t('tbm.加班时间时间重复') });
        this.form.beginTime = '00:00'
        this.form.endTime = '00:00'
        this.form.totalDays = ''
        this.form.totalHours = ''
        return true;
      } else {
        return false;
      }
    },
    sendData () {
      if (this.form.dateDetail !== '') {
        const d = new Date(this.form.dateDetail)
        const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate());
        this.form.dateDetail = resDate;
      }
      var obj = {};
      obj.rowIndex = this.$route.query.rowIndex || -1;
      obj.data = this.form;
      this.$router.replace({
        name: 'tbm/overtimeApplication/overtimeApplicationEdit',
        query: {
          ...this.$route.query
        },
        params: {
          ...this.$route.params,
          obj
        }
      })
    },
    p (s) {
      return s < 10 ? '0' + s : s;
    },
    async checkSealupAndRepeat (tableBeginTime, tableEndTime) {
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOverTime/checkSealupAndRepeat',
        params: {
          dateDetail: this.form.dateDetail,
          beginTime: this.form.beginTime,
          endTime: this.form.endTime,
          pkUser: this.pkUser,
          pkOrg: this.pkOrg

        }
      });
    },
    initData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOverTime/getDataDetail',
        data: {
          entity: {
            id: this.form.id
          }
        }
      }).then(res => {
        this.form = res.data.entity;
      });
    },
  },
  created () {
    let detailId = this.$route.query.detailFormId;
    let dataRow = this.$route.query.dataRow;
    let overTimeList=this.$route.params.list
    this.overTimeList = overTimeList;
    if (detailId) {
      this.form.id = detailId
      this.initData()
    } else if (dataRow) {
      this.form = dataRow;
    } else {
      this.form.deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
      this.form.userName = JSON.parse(localStorage.getItem('userInfo')).cname;
    }
    this.pkOrg = JSON.parse(localStorage.getItem('userInfo')).companyId;
    this.pkUser = JSON.parse(localStorage.getItem('userInfo')).id;
  }
}
</script>
